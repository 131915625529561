import Header from "./components/header";
import Footer from "./components/footer";
import NotFound from "./components/not-found";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Section from "./components/section";
import Section2 from "./components/section2";
import Section3 from "./components/section3";
import Section4 from "./components/section4";
import Section5 from "./components/section5";
import DetailPage from "./components/detailPage";
import AllNfts from "./components/allnfts";

import React from "react";

function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route
            path="/"
            element={
              <section
                className="fullwidth margin-top-0 padding-top-0 padding-bottom-0"
                data-background-color="#f8f8f8"
              >
                <div className="container">
                  <Section />
                  <Section2 />
                  <Section3 />
                  <Section4 />
                  <Section5 />
                </div>
              </section>
            }
          />

          <Route
            path="/details"
            element={
              <section
                className="fullwidth margin-top-0 padding-top-0 padding-bottom-0"
                data-background-color="#f8f8f8"
              >
                <div className="container">
                  <DetailPage />
                </div>
              </section>
            }
          />
          <Route path="/all_nfts/*" element={<AllNfts />} />

          <Route path="/not-found" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/not-found" />} />
        </Routes>
      </BrowserRouter>
      {/* <section
        className="fullwidth margin-top-0 padding-top-0 padding-bottom-0"
        data-background-color="#f8f8f8"
      >
        <div className="container">
          <Section />
          <Section2 />
          <Section3 />
          <Section4 />
          <Section5 />
        </div>
      </section> */}
      <Footer />
    </React.Fragment>
  );
}

export default App;
